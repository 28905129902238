import "./main.css"
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Section_one } from "./HomeSection/Section_one";
import { Section_two } from "./HomeSection/Section_two";
import { Section_three } from "./HomeSection/Section_three";
import { Section_four } from "./HomeSection/Section_four";
import { Section_five } from "./HomeSection/Section_five";
import { Section_six } from "./HomeSection/Section_six";
import { Section_seven } from "./HomeSection/Section_seven";
import { Section_eight } from "./HomeSection/Section_eight";
import HalfSquareButton from './HomeSection/components/HalfSquareButton';
import { Footer } from "./Footer";

export const Home = () => {
  return (
    <ParallaxProvider>
      <main>
        <HalfSquareButton />
        <Section_one />
        <Section_two />
        <Section_three />
        <Section_four />
        <Section_five />
        <Section_six />
        <Section_seven />
        <Section_eight />
        <Footer />
      </main>
    </ParallaxProvider>
  )
}
