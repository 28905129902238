import './App.css';
import { Routes, Route, Link } from "react-router-dom";
// import { LoaderScreen } from "./components/LoaderScreen.js"
import { Home } from "./components/Home";
import { Header } from "./components/Header";


function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>
          That feels like an existential question, don't you
          think?
        </p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}

function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
