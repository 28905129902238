import "./Section.css"
import React from 'react'
import 'react-vertical-timeline-component/style.min.css';
import { Puff } from 'react-loading-icons'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import Puff from "react-loading-icons/dist/esm/components/puff";

// const WORK = [
//     {
//         data: "August",
//         elementTitle: "Art Director",
//         elementSubtitle: "San Francisco, CA",
//         elementP: "Creative Direction, User Experience, Visual Design, SEO, Online Marketing",
//     },
//     {
//         data: "September",
//         elementTitle: "Web Designer",
//         elementSubtitle: "Los Angeles, CA",
//         elementP: "User Experience, Visual Design",
//     },
//     {
//         data: "October",
//         elementTitle: "Art Director",
//         elementSubtitle: "San Francisco, CA",
//         elementP: "User Experience, Visual Design",
//     },
// ]

  export const Section_seven = () => {

    const WORK = [
        {
            id: 1,
            status: "activity",
            textPos: "left",
            data: "Апрель - 2023",
            elementTitle: "Phase1",
            elementSubtitle: "(04/2023~04/2023)",
            elementP: ["Маркетинговая кампания", "Создание сообщества (Discord, Twitter, Telegram)", "Конкурс белых списков", "Мероприятия сообщества", "Запуск открытой бета-версии"],
        },
        {
            id: 2,
            status: "process",
            textPos: "right",
            data: "Апрель - 2023",
            elementTitle: "Phase2",
            elementSubtitle: "(04/2023~04/2023)",
            elementP: ["Улучшение функциональности приложения", "Добавление новых функций и возможностей", "Подключение новых платежных систем", "Расширение каталога музыки", "Стратегическое партнерство"],
        },
        {
            id: 3,
            status: "process",
            textPos: "left",
            data: "Май - 2023",
            elementTitle: "Phase3",
            elementSubtitle: "(04/2023~05/2023)",
            elementP: ["Выпуск полной версии приложения", "Масштабирование команды и инфраструктуры", "Расширение географии охвата пользователей", "Проведение рекламных кампаний и партнерских программ"],
        },
    ]

    const listItems = WORK.map((d) => 
        <VerticalTimelineElement key={d.id}
            className="vertical-timeline-element--work"
            textClassName={ `${d.textPos === "left" ? 'left' : 'right'}` }
            iconClassName = {`img_logo ${d.status === "process" ? 'process' : 'activity'}`}
            contentStyle={{ backgroundImage: 'linear-gradient(-135deg, #6732FF, #9557FF 10%, #4215BF)', color: '#fff', borderRadius: "0.7em", bottom: "15px"}}
            contentArrowStyle={{ borderRight: '0 solid  rgb(33, 150, 243)' }}
            date={d.data}
            dateClassName={`${d.textPos === "right" ? 'left' : 'right'}`}
            iconStyle={{color: '#fff', width: "30px", height: "30px" }}
            
            >
                <h3 className="vertical-timeline-element-title">{d.elementTitle}</h3>
                <h4 className="vertical-timeline-element-subtitle">{d.elementSubtitle}</h4>
                <p style={{ whiteSpace: "pre-line" }}>
                    {/* {d.elementP = WORK.map((x) => <li>{x}</li>)} */}
                    {d.elementP.map((number) =>  <li key={number}>{number}</li>)}
                </p>
        </VerticalTimelineElement>
        );
        // <li key={d.data}>{d.elementTitle}</li>

    return (
        <section id="maps">
            <div className="s6 s6-fon">
                <h2 className="h2 pb5">Карта проекта</h2>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        textClassName={ 'right' }
                        contentStyle={{ backgroundImage: 'linear-gradient(-135deg, #6732FF, #9557FF 10%, #4215BF)', color: '#fff', borderRadius: "0.7em", bottom: "15px"}}
                        contentArrowStyle={{ borderRight: '0 solid  rgb(33, 150, 243)' }}
                        date="Март - 2023"
                        iconClassName = {"img_logo complete"}
                        dateClassName={'left'}
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', width: "30px", height: "30px" }}
                    >
                        <h3 className="vertical-timeline-element-title">Phase0</h3>
                        <h4 className="vertical-timeline-element-subtitle">(03/2023~03/2023)</h4>
                        <p>
                            <li>Построение команды</li>
                            <li>Страница приложения</li>
                            <li>Бета версия приложения</li>
                        </p>
                    </VerticalTimelineElement>
                    {/* <Puff /> */}
                    {listItems}
                </VerticalTimeline>
            </div>
        </section>
    )
  }
