import "./Section.css"
import React, { useState } from 'react'
import { useParallax, Parallax } from 'react-scroll-parallax';

import IconReviews from "./images/s4/Icon-Reviews.svg";
import IconSecure from "./images/s4/Icon-Secure.svg";
import IconExperience from "./images/s4/Icon-Experience.svg";
import IconDecentralized from "./images/s4/Icon-Decentralized.svg";

import GraphicDecentralizedOrbitals from "./images/s4/s4_1.webp";

function DecentralizedOrbitals() {
    const parallax = useParallax({
        // speed: -200,
        opacity: [0, 2], 
        translateY: [80, 6, 'easeOutBack'],
    });
    return <img ref={parallax.ref} src={GraphicDecentralizedOrbitals} loading="lazy"
    alt="" class="s3-orbitals-image" />;
  }

export const Section_four = () => {
  return (
    <section className="s3" id="my-section">
        <div className="s3-container">
            <div className="s3-image-wrapper">
                <div data-w-id="4cbc229b-db71-f03f-795b-834ff486eeaa" className="s3-image-container">
                    <DecentralizedOrbitals />
                </div>
            </div>
            <div data-w-id="dd382521-3a47-1d9a-0f32-82e357f71309" class="s3-info-container">
                <Parallax opacity={[0, 2.5]}>
                    <h2 className="h2 is-small-h2">Музыка везде с вами!</h2>
                    <p className="p is-s3-p">Наслаждайтесь музыкой на любом устройстве. 
                    Наш сервис адаптивен и доступен везде. Наслаждайтесь потоковой музыкой на своем компьютере, 
                    планшете или телефоне. Будьте в курсе новинок и эксклюзивных релизов. 
                    Все ваши плейлисты доступны в любой точке мира.
                    </p>
                    <div className="s3-list-container">
                        <div className="s3-list-item"><img src={IconReviews} loading="lazy" alt=""
                                className="s3-icon" />
                            <div className="s3-item-description">Слушайте музыку где угодно и на чем угодно без ограничений.</div>
                        </div>
                        <div className="s3-list-item"><img src={IconSecure} loading="lazy" alt=""
                                className="s3-icon" />
                            <div className="s3-item-description">Бесперебойная работа на мобильных устройствах, планшетах и компьютерах.</div>
                        </div>
                        <div className="s3-list-item"><img src={IconDecentralized} loading="lazy" alt=""
                                className="s3-icon" />
                            <div className="s3-item-description">Максимальное удобство в использовании на любом устройстве.</div>
                        </div>
                        <div className="s3-list-item"><img src={IconExperience} loading="lazy" alt=""
                                className="s3-icon" />
                            <div className="s3-item-description">Адаптивный интерфейс обеспечивает удобство пользования на любом устройстве.</div>
                        </div>
                    </div>
                </Parallax>
            </div>
        </div>
    </section>
  )
}
