import "./Section.css"
import React, { useState } from 'react'
import { useParallax, Parallax } from 'react-scroll-parallax';

import GraphicOwnedPlanets from "./images/s5/s5_1.webp";

function OwnedPlanetsImg() {
    const parallax = useParallax({
        // speed: -200,
        opacity: [0, 2], 
        translateY: [80, 6, 'easeOutBack'],
    });
    return <img ref={parallax.ref} src={GraphicOwnedPlanets} loading="lazy"
    sizes="(max-width: 479px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 41vw, (max-width: 1439px) 42vw, 598px"
    srcSet={GraphicOwnedPlanets}
    alt="" class="s4-planets-image" />;
}

export const Section_five = () => {
  return (
    <section class="s4">
        <div class="s4-container">
            <div data-w-id="dfca8fac-85d5-84b0-7a50-f89316e1f1d3" class="s4-info-container">
                <Parallax opacity={[0, 2.5]}>
                    <h2 class="h2 is-small-h2 is-s4-h2">Ваша музыка, ваше право выбора.</h2>
                    <p class="p is-s4-p">
                    Музыка – это ваша личная страсть и выбор. Наш музыкальный стриминг сервис настроен на WEB3 и 
                    полностью децентрализован. Вы контролируете свою музыку и данные, не надо беспокоиться о том, 
                    что ваши личные данные уходят в чужие руки. Принадлежность ваших данных только вам. 
                    Откройте для себя новую музыку и поддерживайте своих любимых исполнителей. Web 3 – это будущее, 
                    в котором вы управляете своим миром музыки.
                    </p>
                </Parallax>
            </div>
            <div class="s4-image-wrapper">
                <div data-w-id="5bb8f8fa-644b-11c0-b4de-5a1a38982b81" class="s4-image-container">
                    <OwnedPlanetsImg />
                </div>
            </div>
        </div>
    </section>
  )
}
