import "./Section.css"
import React from 'react'
import { useParallax, Parallax } from 'react-scroll-parallax';

import IconFees from "./images/s6/Icon-Fees.svg";
import IconWithdrawals from "./images/s6/Icon-Withdrawals.svg";
import IconPrivate from "./images/s6/Icon-Private.svg";
import IconInstant from "./images/s6/Icon-Instant.svg";
import IconDevice from "./images/s6/Icon-Device.svg";
import IconSingleAccount from "./images/s6/Icon-Single-Account.svg";

import GraphicLayerBottom from "./images/s6/s6_1.webp";


function LayerBottomImg() {
    const parallax = useParallax({
        // speed: -200,
        opacity: [0, 2], 
        translateY: [80, 6, 'easeOutBack'],
    });
    return <img ref={parallax.ref} src={GraphicLayerBottom} loading="lazy"
    sizes="(max-width: 479px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 41vw, (max-width: 1439px) 42vw, 598px"
    srcSet={GraphicLayerBottom}
    alt="" class="s5-bottom-image" />;
}

export const Section_six = () => {
  return (
    <section class="s5">
          <div class="s5-container">
              <div class="s5-image-wrapper">
                    <div data-w-id="8dd876a8-21f7-d99a-e333-24df4c83038a" class="s5-image-container">
                        <LayerBottomImg />
                    </div>
              </div>
              <div data-w-id="67031d51-0f69-11b7-13bc-3146d884229d" class="s5-info-container">
              <Parallax opacity={[0, 2.5]}>
                  <h2 class="h2 is-small-h2">Наслаждайтесь музыкой без ограничений с нашим стриминговым сервисом.</h2>
                  <div class="div-block-21-copy-copy">
                      <div class="s5-list-item"><img src={IconFees} loading="lazy" alt=""
                              class="s5-list-icon" />
                          <div class="s5-item-description">Никаких ограничений на количество треков и песен.</div>
                      </div>
                      <div class="s5-list-item"><img src={IconWithdrawals} loading="lazy" alt=""
                              class="s5-list-icon" />
                          <div class="s5-item-description">Наслаждайтесь музыкой без рекламы и перерывов.</div>
                      </div>
                      <div class="s5-list-item"><img src={IconPrivate} loading="lazy" alt=""
                              class="s5-list-icon" />
                          <div class="s5-item-description">Слушайте музыку в любое время, где бы вы ни находились.</div>
                      </div>
                      <div class="s5-list-item"><img src={IconInstant} loading="lazy" alt=""
                              class="s5-list-icon" />
                          <div class="s5-item-description">Наш сервис поддерживает все устройства и операционные системы.</div>
                      </div>
                      <div class="s5-list-item"><img src={IconDevice} loading="lazy" alt=""
                              class="s5-list-icon" />
                          <div class="s5-item-description">Быстрый доступ к миллионам песен и новинкам музыкального мира.</div>
                      </div>
                      <div class="s5-list-item"><img src={IconSingleAccount} loading="lazy" alt=""
                              class="s5-list-icon" />
                          <div class="s5-item-description">Выбирайте из широкого выбора жанров и исполнителей.</div>
                      </div>
                  </div>
                </Parallax>
              </div>
          </div>
      </section>
  )
}
