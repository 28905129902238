import './NavBar.css';
import './NavBar.scss'
import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import ModalWindow from '../HomeSection/components/ModalWindow';

import Logo from "./images/LogoDMD.webp";

export const NavBar = () => {
    const [showNav, setShowNav] = useState(false);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
    
        setVisible((prevScrollPos > currentScrollPos && prevScrollPos - 
            currentScrollPos > 7200) || currentScrollPos < 130 || currentScrollPos > 6700);
    
        setPrevScrollPos(currentScrollPos);
      };
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
    
      }, [prevScrollPos, visible, handleScroll]);

    return (
        <header>
            <img className="logo" src={Logo} alt="title" />
            {/* <div className={`menu__button ${showNav === true ? 'open' : ''}`}
                style={{"--y": visible ? '200%' : '0'}}
                onClick={() => setShowNav(!showNav)}
                role="button"
                onKeyDown={() => setShowNav(!showNav)}
                tabIndex={0}
            >
                Menu
            </div> */}

            <nav className={!showNav ? "nav-menu hidden" : "nav-menu"}>
                <div className='menu__header'>
                    <p>Menu</p>
                    <div className='button-close' title="Close" onClick={() => setShowNav(!showNav)}>
                        {/* <TbMenu2 /> */}
                        <IoClose size={40} color={"#9b9b9f"}/>
                    </div>
                </div>
                <div className='menu__links'>
                    <a href="#aboutus" className='nav-menu-link' >О нас</a>
                    <a href="#maps" className='nav-menu-link' >Карта проекта</a>
                    <a href="#community" className='nav-menu-link' >Сообщество</a>
                    {/* <Link to="/" className='nav-menu-link'>О нас</Link>
                    <Link to="/" className='nav-menu-link'>Карта проекта</Link>
                    <Link to="/" className='nav-menu-link'>Сообщество</Link> */}
                </div>
                <div class="menu__contact">
                    <ModalWindow />
                    {/* <button className='start-btn'>Скачать</button> */}
                </div>     
            </nav>
            <div className={!showNav ? "menu__overlay hidden" : "menu__overlay"}></div>
        </header>
    )
}