import "./Section.css"
import React from 'react'
import { Preleasetext } from "./Preleasetext.tsx"

export const Section_two = () => {
  return (
    <section className="s1">
        <div className="s1-wrapper">
            <div className="s1-container">
                <Preleasetext />
                {/* <div class="s1-block-container">
                    <div class="s1-block">
                        <div class="s1-content-container">
                            <h3 class="h3">The total amount of all transactions</h3>
                            <div class="s1-numbers">$<span class="counter">4,200,368,117</span></div>
                        </div>
                        <div class="s1-gradient-glow"></div>
                        <div class="s1-gradient-mask"></div>
                        <div class="s1-gradient-outline"></div>
                    </div>
                </div>
                <div class="s1-block-container is-total-trades-block-container">
                    <div class="s1-block">
                        <div class="s1-content-container">
                            <h3 class="h3 is-green-h3">Total Trades</h3>
                            <div class="s1-numbers"><span class="counter">10,434</span></div>
                        </div>
                        <div class="s1-gradient-glow is-green-gradient-glow"></div>
                        <div class="s1-gradient-mask"></div>
                        <div class="s1-gradient-outline"></div>
                    </div>
                </div>  */}
            </div>
        </div>
    </section>
  )
}
