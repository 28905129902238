import "./Section.css"
import "../main.css"
// import React from 'react'
// import { motion } from "framer-motion";
import React, { Suspense, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import GridLoader from "react-spinners/HashLoader";
import Modal from 'react-modal';

import IconTwitter from "./images/icon-twitter.svg";
import IconYouTube from "./images/icon-youtube.svg";
import ModalWindow from './components/ModalWindow';
import GraphicDecentralizedOrbitals from "./images/mobileback3.webp";

const Spline = React.lazy(() => import('@splinetool/react-spline'));

function H1TextAnimate() {
  return (
      <h1>DMD Music лучшее место для наслаждения любимой музыкой!</h1>
  );
}

function PTextAnimate() {
  return (
      <p>
       DMD Music - это музыкальный стриминговый сервис, который откроет для вас мир музыки в новом измерении. 
       С нашим кроссплатформенным приложением вы можете наслаждаться музыкой в любое время и в любом месте, общаться 
       с другими меломанами и делиться своими любимыми треками. Присоединяйтесь к нам и окунитесь в мир звуков 
       с DMD Music!
      </p>
  );
}

function ButtonAnimate() {
  return (
      <ModalWindow />
  );
}

function BlockAnimate() {
  return (
      <div className="social">
        <div />
        <a href="https://twitter.com/dmdmusicoff">
          <img src={IconTwitter} alt="Twitter" />
        </a>
        <a href="https://www.youtube.com/channel/UC5JB_s7523AoE-_GMTNjirA">
          <img src={IconYouTube} alt="YouTube" />
        </a>
      </div>
  );
}

function DecentralizedOrbitals() {
  return <img className="spline-img" src={GraphicDecentralizedOrbitals} loading="lazy"
  alt="" />;
}

export const Section_one = () => {

  return (
    <section className="elementor-section">
      <div className="elementor-container pt6">
        <div className="elementor-row">
                    <DecentralizedOrbitals />
          {/* <Suspense fallback={<div className="loader"><GridLoader size={60} color={'#FFF'} loading={true}/></div>}>
            <Spline className="spline" id="canvas3d"
                      scene="https://prod.spline.design/7578z082bfirGvfr/scene.splinecode"/>
          </Suspense> */}
          {/* <Spline className="spline" id="canvas3d"
                    scene="https://prod.spline.design/zwOYfDveXGjqsSRd/scene.splinecode"/> */}
          <BlockAnimate />
          <div className='content'>
            <H1TextAnimate />
            <PTextAnimate />
            <ButtonAnimate />
          </div>
        </div>
      </div>
    </section>
  )
}
