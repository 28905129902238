import React, { useState, useEffect } from 'react';

const HalfSquareButton = ({ customStyles }) => {
  const defaultStyles = {
    position: 'fixed',
    padding: '10px 20px',
    backgroundColor: '#3f51b5',
    color: 'white',
    border: 'none',
    borderRadius: '12px',
    backgroundImage: 'linear-gradient(309deg, rgb(214, 25, 249), rgb(87, 23, 243) 50%, rgb(24, 95, 255))',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '20px',
    bottom: '60px',
    opacity: 0,
    pointerEvents: 'none',
    zIndex: 998,
  };

  const [isVisible, setIsVisible] = useState(false);

  const styles = { ...defaultStyles, ...customStyles, opacity: isVisible ? 1 : 0, pointerEvents: isVisible ? 'auto' : 'none' };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button style={styles} onClick={handleClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5 15l7-7 7 7" />
      </svg>
    </button>
  );
};

export default HalfSquareButton;
