import "./Section.css"
import React from 'react'
import { useParallax, Parallax } from 'react-scroll-parallax';

import GraphicMemeCat from "./images/s8/Graphic-Meme-Cat-p-800.png"
import GraphicMemeMessage from "./images/s8/Graphic-Meme-Message-p-800.png"
import GraphicMemeCard from "./images/s8/Graphic-Meme-Card-p-800.png"
import GraphicMemeRocket from "./images/s8/Graphic-Meme-Rocket-p-800.png"

function GraphicMemeCatImg() {
    const parallax = useParallax({
        opacity: [0, 2], 
        translateX: [-20, 0, 'easeInOutCirc'],
    });
    return <img ref={parallax.ref} src={GraphicMemeCat} loading="lazy"
    sizes="(max-width: 479px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 41vw, (max-width: 1439px) 42vw, 598px"
    srcSet={GraphicMemeCat}
    alt="" class="s6-cat-image" />;
}

function GraphicMemeMessageImg() {
    const parallax = useParallax({
        opacity: [0, 3], 
        translateY: [-22, 10, 'easeInOut'],
    });
    return <img ref={parallax.ref} src={GraphicMemeMessage} loading="lazy"
    sizes="(max-width: 479px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 41vw, (max-width: 1439px) 42vw, 598px"
    srcSet={GraphicMemeMessage}
    alt="" class="s6-message-image" />;
}

function GraphicMemeCardImg() {
    const parallax = useParallax({
        opacity: [0, 2], 
        translateY: [0, -10, 'easeInOut'],
    });
    return <img ref={parallax.ref} src={GraphicMemeCard} loading="lazy"
    sizes="(max-width: 479px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 41vw, (max-width: 1439px) 42vw, 598px"
    srcSet={GraphicMemeCard}
    alt="" class="s6-card-image" />;
}

function GraphicMemeRocketImg() {
    const parallax = useParallax({
        opacity: [0, 2], 
        translateY: [0, -10, 'easeInOut'],
    });
    return <img ref={parallax.ref} src={GraphicMemeRocket} loading="lazy"
    sizes="(max-width: 479px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 41vw, (max-width: 1439px) 42vw, 598px"
    srcSet={GraphicMemeRocket}
    alt="" class="s6-rocket-image" />;
}

export const Section_eight = () => {
  return (
    <section class="s7" id="community">
        <div class="s6-container">
            <div data-w-id="649dbab4-e00a-e03a-732f-5ed95dca54ab" class="s6-info-container">
                <Parallax opacity={[0, 2.5]}>
                    <h2 class="h2 is-small-h2-s6">Присоединяйтесь к нашему сообществу и получайте эксклюзивную информацию!</h2>
                    <p class="p is-s6-p">Наше сообщество - не просто сервис музыкального стриминга. 
                    Присоединяйтесь к нам на Discord, Twitter, Telegram, чтобы стать частью нашего крутого сообщества. 
                    Получайте эксклюзивную информацию о наших новинках и общайтесь с единомышленниками. 
                    Присоединяйтесь сейчас, чтобы не упустить возможность быть в курсе всех событий!</p>
                    <a href="https://discord.com/invite/W2dSA3CHJq" class="filled-button w-button">Присоединиться</a>
                </Parallax>
            </div>
            <div class="s6-image-wrapper">
                <div data-w-id="13a914f3-4ae0-191c-55b4-82b0730137d9" class="s6-image-container">
                    <GraphicMemeCatImg />
                    <GraphicMemeMessageImg />
                    <GraphicMemeCardImg />
                    <GraphicMemeRocketImg />
                </div>
            </div>
        </div>
    </section>
  )
}
