import "./HomeSection/Section.css"
import React from 'react'
import { Link } from "react-router-dom";

import Logo from "./NavBar/images/LogoDMDnotNeon.png";

import IconTwitterGray from "./NavBar/images/Icon-Twitter-Gray.svg";
import IconTwitterGreen from "./NavBar/images/Icon-Twitter-Green.svg";
import IconDiscordGray from "./NavBar/images/Icon-Discord-Gray.svg";
import IconDiscordGreen from "./NavBar/images/Icon-Discord-Green.svg";
import IconTelegramGray from "./NavBar/images/Icon-Telegram-Gray.svg";
import IconTelegramGreen from "./NavBar/images/Icon-Telegram-Green.svg";
// import IconDiscordGreen from "./NavBar/images/Icon-Discord-Green.svg";

export const Footer = () => {
  return (
    <section class="footer">
          <div class="footer-container">

              <div class="social-container">
                  <a href="https://twitter.com/dmdmusicoff" target="_blank" class="social-holder w-inline-block">
                    <img src={IconTwitterGray} loading="lazy" alt="" class="gray-social" />
                    <img src={IconTwitterGreen} loading="lazy" alt="" class="green-social" />
                  </a>
                  <a href="https://t.me/dmdmusicofficial" target="_blank"
                      class="social-holder w-inline-block">
                    <img src={IconTelegramGray} loading="lazy"
                          alt="" class="gray-social" />
                    <img src={IconTelegramGreen} loading="lazy" alt=""
                          class="green-social" />
                  </a>
                  <a href="https://discord.com/invite/W2dSA3CHJq" target="_blank"
                      class="social-holder w-inline-block">
                    <img src={IconDiscordGray} loading="lazy"
                          alt="" class="gray-social" />
                    <img src={IconDiscordGreen} loading="lazy" alt=""
                          class="green-social" />
                  </a>
              </div>
              <img src={Logo} loading="lazy" alt="" class="footer-logo" />
              <div class="footer-legal">Copyright © <span class="copyright-year">2023</span> DMD Music All rights
                  reserved
              </div>

          </div>
      </section>
  )
}
