import "./Section.css"
import React from 'react'
import { motion } from "framer-motion";

import GraphicLeverage from "./images/s3/music1.webp";
import GraphicPools from "./images/s3/music2.webp";
import GraphicTransactions from "./images/s3/music3.webp";
import GraphicExecution from "./images/s3/music4.webp";
import GraphicFees from "./images/s3/music5.webp";
import GraphicOracle from "./images/s3/music6.webp";

function FadeInWhenVisible({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 1.2 }}
        variants={{
          visible: { opacity: 1, scale: 1, y: 0 },
          hidden: { opacity: 0, scale: 0, y: 100 }
        }}
      >
        {children}
      </motion.div>
    );
}

function CardVisible({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ 
            delay: 0.3,
            duration: 0.4 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 200 }
        }}
      >
        {children}
      </motion.div>
    );
}

function CardVisible2({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ 
            delay: 0.4,
            duration: 0.4 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 200 }
        }}
      >
        {children}
      </motion.div>
    );
}

function CardVisible3({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ 
            delay: 0.5,
            duration: 0.4 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 200 }
        }}
      >
        {children}
      </motion.div>
    );
}

function CardVisible4({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ 
            delay: 0.3,
            duration: 0.4 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 200 }
        }}
      >
        {children}
      </motion.div>
    );
}

function CardVisible5({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ 
            delay: 0.4,
            duration: 0.4 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 200 }
        }}
      >
        {children}
      </motion.div>
    );
}

function CardVisible6({ children }) {
    return (
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ 
            delay: 0.5,
            duration: 0.4 }}
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 200 }
        }}
      >
        {children}
      </motion.div>
    );
}

export const Section_three = () => {
  return (
    <section class="s2" id="aboutus">
            <div class="s2-wrapper">
                <div data-w-id="5b6da96c-f3bc-7ac5-0a8a-bf7907321711" class="s2-info-container">
                    <FadeInWhenVisible> 
                        <h2 class="h2">Получите уникальный музыкальный опыт с нашим мобильным приложением!</h2>
                    </FadeInWhenVisible>
                    <FadeInWhenVisible> 
                        <p class="p is-s2-p">Мы предоставляем самый широкий выбор музыкальных жанров и исполнителей, 
                        которые доступны для вас в любое время и в любом месте. Наше приложение позволяет вам создавать 
                        свои собственные плейлисты, делиться музыкой с друзьями и получать рекомендации на 
                        основе ваших предпочтений. Никаких ограничений, никаких рекламных перерывов, 
                        только музыка в вашем распоряжении!
                        </p>
                    </FadeInWhenVisible>
                </div>
                <div class="s2-blocks-wrapper">
                    <div data-w-id="a07dd5fa-e232-2d4d-0204-e7a5e93200e2" class="s2-blocks-container">
                        <div data-w-id="0f9b7b89-f010-ae8c-147c-72ef0f4a1659" class="s2-block">
                            <CardVisible>
                            <div class="s2-content-container">
                                <div class="s2-blur-container">
                                    <div class="s2-blur"></div>
                                </div>
                                <div class="s2-icon-container">
                                        <motion.div 
                                            whileHover={{ scale: 1.1, y: -10 }}
                                            transition={{ type: "spring", stiffness: 150, damping: 10 }}
                                        >
                                            <img src={GraphicLeverage} loading="lazy"alt="" class="s2-icon" />
                                        </motion.div>
                                </div>
                                <h3 class="s2-h3">Удобный поиск музыки</h3>
                            </div>
                            </CardVisible>
                        </div>
                        <div class="s2-block">
                            <CardVisible2>
                                <div class="s2-content-container">
                                    <div class="s2-blur-container">
                                        <div class="s2-blur is-blur-2"></div>
                                    </div>
                                    <div class="s2-icon-container">
                                        <motion.div 
                                            whileHover={{ scale: 1.1, y: -10 }}
                                            transition={{ type: "spring", stiffness: 150, damping: 10 }}
                                        >
                                            <img src={GraphicPools} loading="lazy" alt=""
                                                class="s2-icon is-icon-2" />
                                        </motion.div>
                                    </div>
                                    <h3 class="s2-h3">Высокое качество звука и проигрывания музыки</h3>
                                </div>
                            </CardVisible2>
                        </div>
                        <div class="s2-block">
                            <CardVisible3>
                                <div class="s2-content-container">
                                    <div class="s2-blur-container">
                                        <div class="s2-blur is-blur-3"></div>
                                    </div>
                                    <div class="s2-icon-container">
                                        <motion.div 
                                            whileHover={{ scale: 1.1, y: -10 }}
                                            transition={{ type: "spring", stiffness: 150, damping: 10 }}
                                        >
                                            <img src={GraphicTransactions} loading="lazy"
                                            alt="" class="s2-icon is-icon-3" />
                                        </motion.div>
                                        </div>
                                    <h3 class="s2-h3">Широкий выбор музыкального контента</h3>
                                </div>
                            </CardVisible3>
                        </div>
                        <div class="s2-block">
                            <CardVisible4>
                                <div class="s2-content-container">
                                    <div class="s2-blur-container">
                                        <div class="s2-blur is-blur-4"></div>
                                    </div>
                                    <div class="s2-icon-container">
                                        <motion.div 
                                            whileHover={{ scale: 1.1, y: -10 }}
                                            transition={{ type: "spring", stiffness: 150, damping: 10 }}
                                        >
                                            <img src={GraphicExecution} loading="lazy"
                                                alt="" class="s2-icon is-icon-4" />
                                        </motion.div>
                                    </div>
                                    <h3 class="s2-h3">Простой и интуитивно понятный интерфейс</h3>
                                </div>
                            </CardVisible4>
                        </div>
                        <div class="s2-block">
                            <CardVisible5>
                                <div class="s2-content-container">
                                    <div class="s2-blur-container">
                                        <div class="s2-blur is-blur-5"></div>
                                    </div>
                                    <div class="s2-icon-container">
                                        <motion.div 
                                                whileHover={{ scale: 1.1, y: -10 }}
                                                transition={{ type: "spring", stiffness: 150, damping: 10 }}
                                        >
                                            <img src={GraphicFees} loading="lazy" alt=""
                                                class="s2-icon is-icon-5" />
                                        </motion.div>
                                    </div>
                                    <h3 class="s2-h3">Бесперебойный поток музыкального контента</h3>
                                </div>
                            </CardVisible5>
                        </div>
                        <div class="s2-block">
                            <CardVisible6>
                                <div class="s2-content-container">
                                    <div class="s2-blur-container">
                                        <div class="s2-blur is-blur-6"></div>
                                    </div>
                                    <div class="s2-icon-container">
                                        <motion.div 
                                                whileHover={{ scale: 1.1, y: -10 }}
                                                transition={{ type: "spring", stiffness: 150, damping: 10 }}
                                        >
                                            <img src={GraphicOracle} loading="lazy"
                                                alt="" class="s2-icon is-icon-6" />
                                        </motion.div>
                                    </div>
                                    <h3 class="s2-h3">Персонализированные рекомендации музыки</h3>
                                </div>
                            </CardVisible6>
                        </div>
                </div>
            </div>
        </div>
      </section>
  )
}
